<template>
  <standard-layout>
    <template v-slot:heading>
      <h1>Deine Gastgeber stellen sich vor</h1>
    </template>
    <template v-slot:main>
      <div class="row">
        <div class="col-12 col-md-4">
          <cld-image public-id="carousel/l465hr1wxz9qn8ohodw8"
                     class="img-fluid"
                     height="600"
                     responsive="true"
                     width="auto"
                     crop="fill">
          </cld-image>
        </div>
        <div class="col-12 col-md-8 mt-3">
          <p>
            Willkommen auf dem SPABoot! Ich bin Nicole. Zusammen mit dem ganzen SPABoot-Team sind wir deine Gastgeber
            für einzigartige Entspannung und Wellness auf dem Wasser.
          </p>
          <p>
            Mit meiner Leidenschaft für Wellness und jahrelanger Erfahrung in Planung und Beratung rund um Whirlpools und Saunen betreibe
            ich das einzige Saunaboot Sachsens - das SPABoot, eine schwimmende Oase der Erholung.
          </p>
          <p>
            Mein Ziel ist es, dass du dich auf dem SPABoot willkommen und entspannt fühlst. Genieße die sanften Wellen
            und die Wärme der Sauna, während du dem Alltagsstress entfliehst.
          </p>
          <p>
            Begib dich an Bord des SPABoot und erlebe die totale Entspannung – das SPABoot-Team und ich freuen uns
            darauf, dich persönlich zu begrüßen und dir unvergessliche Momente zu bereiten.
          </p>
          <div class="d-flex">
            <cld-image public-id="unterschrift_nicole"
                       height="60"
                       width="auto"
                       crop="scale"/>
          </div>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>

import {DateTime} from "luxon";

export default {
  computed: {
    evyAge() {
      return Number.parseInt("" + (-DateTime.local(1979, 8, 20).diffNow("years").years))
    },
    guidoAge() {
      return Number.parseInt("" + (-DateTime.local(1972, 5, 15).diffNow("years").years))
    }
  },
  components: {},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
